<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import programmeCardVue from "../../../components/widgets/programmeCard.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
    programmeCardVue,
  },

  data() {
    return {
      isAuthError: false,
      authError: "",
      route1: "",
      route2: "/projects/updateProgramme",
      route3: "",
      progress: 0,
      titre: "",
      programme: {
        titre: "",
        description: "",
        duree: "",
        process: false,
      },
      modalOk: false,
      categ: {
        titre: "",
        description: "",
      },
      categories: [],
      channels: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des Programmes",
      items: [
        {
          text: "Planifications",
          // href: "/projects/programmes",
        },
        {
          text: "Programmes",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    imageFileSeelcted(event) {
      this.imageSelectFile = event.target.files[0];
    },
    imagePFileSeelcted(event) {
      this.imagePSelectFile = event.target.files[0];
    },
    videoSelect(event) {
      this.video = event.target.files[0];
    },
    createProgramme() {
      if (
        !this.imageSelectFile ||
        !this.imagePSelectFile ||
        !this.programme.titre ||
        !this.video ||
        !this.programme.description ||
        !this.programme.duree
      ) {
        this.isAuthError = true;
        this.authError = "Veuillez renseigner tous les champs obligatoires";
      } else {
        this.modalOk = false;
        this.process = true;
        this.titre = "Création de programme";
        const onUploadProgress = (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        };
        Api.postFormData(
          "/streamvod/rest/programme/create-programme",
          {
            file: this.imageSelectFile,
            fileplateau: this.imagePSelectFile,
            name: this.programme.titre,
            file3: this.video,
            description: this.programme.description,
            duree: this.programme.duree,
          },
          onUploadProgress
        )
          .then(() => {
            this.process = false;
            Swal.fire("Succes!", "Programme Ajoutée", "success");
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            Erreur.gestionErreur(error.message);
          });
      }
    },
    getProgrammes() {
      Api.get("/streamvod/rest/programme/all")
        .then((response) => {
          this.programmes = response.data.content;
        })
        .catch((error) => {
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.getProgrammes();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px">
        <button
          type="submit"
          class="btn c2play-primary"
          style="position: relative; left: 90%"
          @click="showModal"
        >
          {{ $t("addButton") }}
        </button>
      </div>
    </div>

    <br /><br />

    <div class="row">
      <programmeCardVue
        v-for="programme in programmes.slice().reverse()"
        :key="programme.id"
        :programme="programme"
      ></programmeCardVue>
    </div>

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      :title="$t('pages.programme.formulaire.title')"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-6 mb-3">
              <label for="projectname" class="col-form-label">{{
                $t("pages.programme.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="programme.titre"
                  :placeholder="
                    $t('pages.programme.formulaire.titre.placeholder')
                  "
                />
              </div>
            </div>

            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label">{{
                $t("pages.programme.formulaire.duree.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  v-model="programme.duree"
                  class="form-control"
                  placeholder="hh:mm:ss"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imageFileSeelcted($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePFileSeelcted($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Vidéo de couverture</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="videoSelect($event)"
                  class="form-control"
                  accept=".MKV, .AVI, .MP4, MOV"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label">Description</label
            ><span style="color: red; font-size: 1.2em">*</span>

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="programme.description"
                :placeholder="
                  $t('pages.programme.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
        <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              style="position: relative; left: 40%"
              @click="createProgramme"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end row -->
    <loader-process :visible="process" :progress="progress" :title="titre" />
  </Layout>
</template>

<style>
</style>